import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IndexCustomer } from "../componens/customer/IndexCustomer";
import { Index } from "../componens/dashboard/Index";
import { FormCustomer } from "../componens/customer/components/forms/FormCustomer";
import { DetailComponent } from "../componens/customer/components/DetailComponent";
import { MainMenu } from "../componens/header/MainMenu";
import { MenuHeader } from "../componens/header/MenuHeader";

import iconMas from "../componens/header/img/mas.svg";

export const DashboardRoutes = () => {
  return (
    <>
      <MenuHeader />
      <div className="main">
        <div className="side-open">
          <img src={iconMas} alt="3 Angles Group" />
        </div>

        <MainMenu />
        <div className="content">
          <Switch>
            <Route exact path="/dashboard" component={Index} />
            <Route exact path="/dashboard/customer" component={IndexCustomer} />
            <Route
              exact
              path="/dashboard/customer-store"
              component={FormCustomer}
            />
            <Route
              exact
              path="/dashboard/customer/detail/:id"
              component={DetailComponent}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
