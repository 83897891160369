import React from "react";
import { Bar } from "react-chartjs-2";

export const BarChart = () => {
  const data = {
    labels: [
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
      "Enero",
      "Febrero",
    ],
    datasets: [
      {
        label: "Clientes potenciales",
        backgroundColor: "#B0DB20",
        borderColor: "#B0DB20",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [165, 59, 0, 0, 0, 0, 0],
      },
    ],
  };
  return (
    <div className="contain-bar-chart">
      <Bar
        data={data}
        width={100}
        height={50}
        options={{
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
};
