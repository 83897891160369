import React from "react";
import { Link } from "react-router-dom";
import "./css/style.css";
import logo from "../../img/logo-morandi.svg";
import menu from "./img/menu.svg";
import icon from "./img/icon-2.svg";

// const url = "https://static-cse.canva.com/image/55043/photos@2x.4b5d6e26.jpg";
export const MenuHeader = () => {
  return (
    <div className="header">
      <div className="logo">
        <img src={logo} alt="3 Angles Group" />
      </div>

      {/* <ul className="menu">
        <li className="active">
          <a href="#">
            <span>Inicio</span>
          </a>
        </li>
        <li className="dropdown">
          <a>
            <span>Lo que sea</span>
          </a>
          <ul className="sub">
            <li>
              <img src={url} />
              <p>Alguna cosa</p>
            </li>
            <li>
              <img src={url} />
              <p>Alguna cosa</p>
              <p>Alguna cosa</p>
              <p>Alguna cosa</p>
            </li>
            <li>
              <img src={url} />
              <p>Alguna cosa</p>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            <span>#AlgunaCosa</span>
          </a>
        </li>
      </ul> */}

      <div className="menu-right">
        <div className="menu-open">
          <img src={menu} alt="3 Angles Group" />
        </div>

        <Link to="/users">
          <img src={icon} alt="3 Angles Group" />
        </Link>

        <div className="profile">
          <img src={icon} alt="3 Angles Group" />
          <div className="sub">
            <div className="name">
              <img src={icon} alt="3 Angles Group" />
              <p>
                Admin<span>admin@3anglesgroup.com</span>
              </p>
            </div>
            <div>
              <Link to="/users">Salir</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
