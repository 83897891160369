import React from "react";

export const CardProject = ({ customer }) => {
  console.log(customer);
  return (
    <div className="card-proyectos">
      <div className="card-project-body">
        <div className="img-project"></div>
        <div className="project-description">
          <p>
            Proyecto: <span>{customer.interes}</span>
          </p>
          <p>
            Valor: <span>$-</span>
          </p>
          <p>
            Medio: <span>{customer.entere}</span>
          </p>
          <p>
            Fecha: <em>2020-10-03</em>
          </p>
        </div>
      </div>
    </div>
  );
};
