import React from "react";
import "./App.css";

import { AppRoute } from "./routers/AppRoute";

function App() {
  return (
    <>
      <AppRoute />
    </>
  );
}

export default App;
