import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { getAxiosCustomer } from "../../helpers/getAxiosCustomer";
import { DetailCustomer } from "../customer/components/DetailCustomer";

import "./css/style.css";

const columns = [
  {
    name: "Nombre",
    selector: "nombre",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
  {
    name: "Fecha",
    selector: "created_at",
    sortable: true,
  },
];

export const DataTableReac = ({ goTo }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    name: "",
  });
  const [items, setData] = useState({
    data: [],
  });

  useEffect(() => {
    setLoading(true);
    getAxiosCustomer(page, filter).then((res) => {
      setData(res);
      console.log(res);
      setLoading(false);
    });
  }, [page, filter]);

  const handleRowClicked = (row) => {
    console.log(`${row.nombre} was clicked!`);
  };

  const handleFilter = (e) => {
    console.log(e.target.value);
    setFilter({
      name: e.target.value,
    });
  };
  const handlePerRowsChange = (per_page) => {
    console.log(per_page);
  };

  const paginatePage = (current_page) => {
    console.log(current_page);
    console.log(items.current_page);
    if (current_page > items.last_page) {
      setPage(items.last_page);
    } else {
      setPage(current_page);
    }
  };

  return (
    <div>
      <div className="contain-search">
        <input
          type="text"
          placeholder="Buscar"
          value={filter.name}
          onChange={handleFilter}
        />
      </div>
      <DataTable
        title="Cientes"
        columns={columns}
        data={items.data}
        // onSort={handleSort}
        sortServer={true}
        progressPending={loading}
        paginationTotalRows={items.total}
        persistTableHead
        pagination={true}
        paginationServer
        onChangePage={paginatePage}
        onChangeRowsPerPage={handlePerRowsChange}
        expandableRows
        expandableRowsComponent={<DetailCustomer goTo={goTo} />}
        // selectableRows
        onRowClicked={handleRowClicked}
        contextMessage={{
          singular: "cliente",
          plural: "clientes",
          message: "seleccionado",
        }}
        striped
        highlightOnHover
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "All",
        }}
      />
    </div>
  );
};
