import axios from "axios";

export const getAxiosCustomer = async (page, filter) => {
  const url = `http://localhost:8000/api/customer?page=${page}`;
  const urlhttps = `https://morandi.com.co/api/customer?page=${page}`;
  const customer = await axios
    .post(urlhttps, filter)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return customer;
};
