import React from "react";
import { DataTableReac } from "../datatables/DataTableReac";
import "./css/style.css";

export const IndexCustomer = (props) => {
  const { history } = props;

  const goTo = (id) => {
    history.push(`/dashboard/customer/detail/${id}`);
  };

  return (
    <div>
      <div className="header-customer">
        <h1>Clientes</h1>
        {/* <Link to="/customer-store">
          <Fab size="medium" color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Link> */}
      </div>

      <div className="container-customer">
        <div className="container-table-customer">
          {/* DataTable material */}
          {/* <DataTableTest goTo={goTo} customer={customer} /> */}
          {/* DataTableLaravel */}
          <DataTableReac goTo={goTo} />
        </div>
      </div>
    </div>
  );
};
