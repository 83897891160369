import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import "./css/style.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "25ch",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FormCustomer = () => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <Link to="/customer">
          <p>Clientes </p>
        </Link>
      </div>
      <div className="container-form-customer">
        <form className={classes.root} noValidate autoComplete="off">
          <div>
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField required id="standard-required" label="Nombre" />

            <TextField
              id="standard-number"
              label="Number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              id="standard-helperText"
              label="Datos"
              defaultValue=""
              helperText="Algo importante"
            />
          </div>
          <div>
            <TextField
              required
              id="filled-required"
              label="Nombre"
              variant="filled"
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Proyecto
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Uno</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required"
              label="Nombre"
              variant="outlined"
            />
            <TextField required id="standard-required" label="Nombre" />

            <TextField
              id="standard-number"
              label="Number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              id="standard-helperText"
              label="Datos"
              defaultValue=""
              helperText="Algo importante"
            />
          </div>
          <div>
            <TextField
              required
              id="filled-required"
              label="Nombre"
              variant="filled"
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Proyecto
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Uno</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button variant="contained" color="primary">
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
