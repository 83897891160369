import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IndexCustomer } from "../componens/customer/IndexCustomer";

import "../App.css";

import { DashboardRoutes } from "./DashboardRoutes";
import { LoginScreen } from "../componens/login/LoginScreen";

export const AppRoute = () => {
  return (
    <Router>
      <Switch>
        <Route path="/dashboard" component={DashboardRoutes} />
        <Route exact path="/" component={LoginScreen} />
      </Switch>
    </Router>
  );
};
