import React from "react";

export const CardNote = ({ data, deleteNota }) => {
  const { description, created_at, id } = data;

  return (
    <div className="card-note">
      <div className="card-note-header">
        <p>
          Usuario: <span>Admin</span>
        </p>
        <div className="container-delete-nota">
          <em>{created_at}</em>
          <button onClick={() => deleteNota(id)}>Eliminar nota</button>
        </div>
      </div>

      <div className="card-note-body">
        <p>{description}</p>
      </div>
    </div>
  );
};
