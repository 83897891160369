import React, { useState } from "react";
import "./css/style.css";
import LogoLogin from "../../img/logo-login.svg";
import IconCohete from "../../img/icon-cohete.svg";
import axios from "axios";

export const LoginScreen = (props) => {
  const { history } = props;

  const goTo = (id) => {};

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    let form = {
      email: email,
      password: password,
    };
    await axios
      .post("https://morandi.com.co/api/login-user", form)
      .then((res) => {
        console.log(res.data);
        if (res.data == 200) {
          history.push(`/dashboard`);
        } else {
          alert("Datos incorrectos");
        }
      });
  };

  return (
    <div className="contain-login">
      <div className="contain-info-login">
        <img className="logo-login" src={LogoLogin} />
        <h1>Soluciones Creativas!</h1>
        <div className="container-cohete">
          <img className="icon-cohete" src={IconCohete} />
          <p>Te llevamos a otro nivel…</p>
        </div>
      </div>
      <div className="contain-form-login">
        <div className="form-login">
          <div className="form-group-login">
            <input
              placeholder="Usuario"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group-login">
            <input
              placeholder="Contraseña"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="contain-button-login">
            <button onClick={login}>Login</button>
            <p>Olvidé mi contraseña</p>
          </div>
        </div>
      </div>
    </div>
  );
};
