import React from "react";
import "./css/style.css";
import { BarChart } from "./components/charts/BarChart";
import { DoughnutChar } from "./components/charts/DoughnutChar";
import { WhatsappConfig } from "../customer/components/morandi/WhatsappConfig";

export const Index = () => {
  return (
    <div className="container-dahsboard">
      <h1>Dashboard</h1>
      <div className="container-charts">
        <BarChart />
        <DoughnutChar />
      </div>
      <div className="container-cards">
        <WhatsappConfig />
      </div>
    </div>
  );
};
