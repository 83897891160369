import React, { useState, useEffect } from "react";
import IconWp from "../../../../img/icon-whatsapp.svg";
import axios from "axios";
export const WhatsappConfig = () => {
  const [numero, setNumero] = useState("");
  const [titular, setTitular] = useState("");
  const [whatsappData, setWhatsappData] = useState({});

  const handleWp = (e) => {
    setNumero(e.target.value);
  };
  const handleTitular = (e) => {
    setTitular(e.target.value);
  };
  useEffect(() => {
    getWhatsappContact();
  }, []);

  const getWhatsappContact = async () => {
    await axios.get("https://morandi.com.co/api/get-whatsapp").then((res) => {
      console.log(res.data);
      setWhatsappData(res.data);
    });
  };

  const storeNumeroWhatsapp = async () => {
    let formData = {
      id: whatsappData.id,
      numero: numero,
      titular: titular,
    };
    let url = "https://morandi.com.co/api/whatsapp-update";
    await axios
      .post(url, formData)
      .then((res) => {
        console.log(res.data);
        setWhatsappData(res.data);
        setNumero("");
        setTitular("");
      })
      .catch();
  };

  return (
    <div className="container-whatsapp-config">
      <div className="head-whatsapp-config">
        <p>
          NÚMERO ACTUAL: <span>{whatsappData.numero}</span>
        </p>
        <p>
          TITULAR ACTUAL: <span>{whatsappData.titular}</span>
        </p>
      </div>
      <div className="body-whatsapp-config">
        <div>
          <input
            placeholder="Número whatsapp"
            type="text"
            value={numero}
            onChange={handleWp}
          />
          <input
            type="text"
            placeholder="Nombre titular"
            value={titular}
            onChange={handleTitular}
          />
          <button onClick={storeNumeroWhatsapp}>Actualizar</button>
        </div>
        <img src={IconWp} />
      </div>
    </div>
  );
};
