import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CardNote } from "./morandi/CardNote";
import { CardProject } from "./morandi/CardProject";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import load from "../../../img/loading.svg";

import axios from "axios";
import {
  CardProjecSkeleton,
  FormDataSkeleton,
} from "../../skeleton/CardProjecSkeleton";

export const DetailComponent = () => {
  const params = useParams();
  const [customer, setCustomer] = useState({});
  const [descripcion, setDescripcion] = useState();
  const [notas, setNotas] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getCustomer();
    console.log(customer);
  }, [notas, loading]);

  const getCustomer = async () => {
    setLoadingData(true);
    await axios
      .get(`https://www.morandi.com.co/api/customer/${params.id}`)
      .then((res) => {
        setCustomer(res.data);
        setLoadingData(false);
      });
  };

  const onChange = (e) => {
    // setCards(...cards, {
    //   description: e.target.value,
    //   name: "Juan camilo",
    //   fecha: "2020-15-03",
    // });
    console.log(e.target.value);

    setDescripcion(e.target.value);
  };

  const crearDescripcion = async () => {
    setLoading(true);
    let nota = {
      description: descripcion,
      user_id: 1,
      cliente_id: customer.id,
    };
    await axios
      .post("https://www.morandi.com.co/api/nota", nota)
      .then((res) => {
        console.log(res.data);
        setNotas(res.data);
        setDescripcion("");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteNota = async (id) => {
    setLoading(true);
    await axios
      .get("https://www.morandi.com.co/api/delete-nota/" + id)
      .then((res) => {
        setLoading(false);
      })
      .catch();
  };

  return (
    <>
      <div className="menu-nav-page">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/customer">
            Clientes
          </Link>

          <Typography color="textPrimary">Historial</Typography>
        </Breadcrumbs>
      </div>
      <div className="container-detail-component">
        <div className="container-personal-data">
          {loadingData ? (
            <FormDataSkeleton />
          ) : (
            <div className="personal-data">
              <h3>Datos personales </h3>
              <div className="container-dato-input">
                <div className="dato-input">
                  <p>Nombres</p>
                  <input type="text" value={customer.nombre} />
                </div>
                <div className="dato-input ">
                  <p>Apellidos</p>
                  <input type="text" value={customer.lastname} />
                </div>
                <div className="dato-input">
                  <p>Email</p>
                  <input type="text" value={customer.email} />
                </div>
                <div className="dato-input ">
                  <p>Cédula</p>
                  <input type="text" value={customer.cedula} />
                </div>
                <div className="dato-input ">
                  <p>Teléfono</p>
                  <input type="text" value={customer.tel} />
                </div>
              </div>

              <div className="datos-btn">
                <button>Actualizar</button>
              </div>
            </div>
          )}

          <div className="container-cards-proyectos">
            {loadingData ? (
              <CardProjecSkeleton />
            ) : (
              <CardProject customer={customer} />
            )}
          </div>
        </div>

        <div className="container-card-history">
          <div className="cart-history">
            <h4>Registro de actividad</h4>
            <div className="history-textarea">
              <textarea value={descripcion} onChange={onChange}></textarea>
            </div>
            <div className="history-btn">
              {loading ? (
                <img src={load} />
              ) : (
                <button onClick={() => crearDescripcion()}>Crear</button>
              )}
            </div>
          </div>
          {customer.notas?.map((data, index) => {
            console.log(index);
            return <CardNote data={data} deleteNota={deleteNota} />;
          })}
        </div>
      </div>
    </>
  );
};
