import React from "react";
import { NavLink } from "react-router-dom";
import icon1 from "./img/icon-1.svg";

export const MainMenu = () => {
  return (
    <div className="sidebar">
      <div className="submenu">
        <div className="btn-cerrar-menu">
          <button>cerrar</button>
        </div>
        <ul>
          <NavLink exact to="/dashboard" activeClassName="active">
            <img src={icon1} alt="hola" />
            Dashboard
          </NavLink>
          <NavLink to="/dashboard/customer" activeClassName="active">
            <img src={icon1} alt="3 Angles Group" />
            Clientes
          </NavLink>

          {/* <li className="dropdown">
            <a href="#">
              <img src={icon1} />
              <span>Lo que sea</span>
            </a>
            <ul className="sub">
              <NavLink exact to="/customer" activeClassName="active">
                Clientes
              </NavLink>
              <li>
                <a href="#">
                  <span>Otra cosa sub</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Sub de algo</span>
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </div>
  );
};
