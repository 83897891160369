import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export const CardProjecSkeleton = () => {
  return (
    <div>
      <Skeleton variant="text" />
      {/* <Skeleton variant="circle" width={40} height={40} /> */}
      <Skeleton variant="rect" width={210} height={100} />
    </div>
  );
};
export const FormDataSkeleton = () => {
  return (
    <div>
      <Skeleton variant="text" width={650} />
      {/* <Skeleton variant="circle" width={40} height={40} /> */}
      <Skeleton variant="rect" width={650} height={200} />
    </div>
  );
};
