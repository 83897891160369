import React from "react";

export const DetailCustomer = ({ data, goTo }) => {
  return (
    <div className="detail-customer">
      {/* <div className="detail-card-header">
        <p>Detalle del cliente</p>
      </div> */}
      <div className="detail-body">
        <div className="info-box-detail">
          <h2>Datos Personales</h2>
          <div className="datos-detail">
            <p>NOMBRE</p>
            <span>{data.nombre}</span>
          </div>
          <div className="datos-detail">
            <p>EMAIL</p>
            <span>{data.email}</span>
          </div>
          <div className="datos-detail">
            <p>TELÉFONO</p>
            <span>{data.tel}</span>
          </div>
          <div className="datos-detail">
            <p>INTERES</p>
            <span>{data.interes}</span>
          </div>
          <div className="datos-detail">
            <p>MEDIO</p>
            <span>{data.entere}</span>
          </div>
        </div>
        <div className="action-detail-customer">
          <h2>Seguimiento</h2>
          <button onClick={() => goTo(data.id)}>Ver historial</button>
        </div>
        <div className="action-detail-customer"></div>
      </div>
    </div>
  );
};
