import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./css/style.css";

export const DoughnutChar = () => {
  const data = {
    labels: ["PACK-1", "PACK-2", "PACK-3"],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
  return (
    <div className="contain-doundhnut">
      <Doughnut data={data} width={80} height={55} />
    </div>
  );
};
